import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import Header from '../../components/header';
import Footer from '../../components/footer';
import SEO from '../../components/seo';
import userServices from '../../services/userServices';
import { device } from '../../constants/device';
import {
  Title,
  TitleBody,
  ArticleDate,
  ArticleImage,
  Separator,
  ArticleFigure
} from '../../components-pure/ArticleDetails';
import HTMLContent from '../../components/Content';

const keywords = [
  'National Freight Index',
  'road',
  'Trucking',
  'Prices Rates',
  'India',
  'Spot Rate',
  'Container',
  'Open',
  'Trailer',
  'rupees per ton km',
  'road trends'
];

const InsightBody = styled.main`
  background: #ffffff;
  .layout {
    width: 100%;
    max-width: 1230px;
    margin: 0 auto;
    padding: 0 15px;
  }
  .heroSection {
    padding: 50px 0;
    position: relative;
    cursor: pointer;
  }
  .carouselWrpr {
    position: absolute;
    width: 70%;
    max-width: 680px;
    background: #ffffff;
    border-radius: 4px;
    padding: 10px 35px 30px;
    right: 40px;
    bottom: 0px;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.2);
  }
  .carouselItem {
    display: flex;
    flex-direction: column;
    p {
      span,
      em {
        display: block;
        padding: 5px 0 0;
      }
    }
  }
  .articleWrpr {
    padding: 50px 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 0 -60px;
    .col {
      flex-grow: 1;
      min-width: 33.33%;
      max-width: 33.33%;
      padding: 30px 60px;
    }
    figure {
      cursor: pointer;
    }
  }

  @media ${device.tablet} {
    .heroSection {
      padding-bottom: 0;
    }
    .articleWrpr {
      padding: 0px 0 30px;
      margin: 0;
      .col {
        min-width: 100%;
        max-width: 100%;
        padding: 20px 0;
      }
    }
    .carouselWrpr {
      width: 90%;
      margin: 0 auto;
      top: -40px;
      padding: 10px 20px 15px;
      right: auto;
      bottom: auto;
      position: relative;
    }
  }
`;
const MainTitle = styled(Title)`
  && {
    font-size: 24px;
    padding-bottom: 0;
    min-height: 78px;
    @media ${device.tablet} {
      font-size: 18px;
      padding: 10px 0;
    }
  }
`;

function myCompFunction(a, b) {
  if (a.node.order < b.node.order) {
    return -1;
  } else {
    return 1;
  }
}

export default class insights extends Component {
  state = {
    isUserLoggedIn: false,
    userMobile: null
  };

  async componentWillMount() {
    if (typeof window !== 'undefined') {
      if (window.localStorage.getItem('sessionToken')) {
        this.setState({ isXhrInProgress: true });
        let checkUser = await userServices.authenticateUser();
        this.setState({ isXhrInProgress: false });
        if (checkUser.mobile !== '') {
          window.localStorage.setItem('userMobile', checkUser.mobile);
          this.setState({ userMobile: checkUser.mobile, isUserLoggedIn: true });
        } else {
          window.localStorage.removeItem('sessionToken');
          window.localStorage.removeItem('userMobile');
        }
      }
    }
  }

  articleThumbnailClick = (article) => {
    userServices.sendAnalyticsEvents({
      category: 'Insights Home',
      action: 'Clicks',
      label: article
    });
  };

  render() {
    const { isUserLoggedIn, userMobile } = this.state;
    const data = this.props.data;
    const sortedData = data.allContentfulArticles.edges.sort((a, b) => myCompFunction(a, b));
    let content = '';
    try {
      content = sortedData[0].node.articleDescription.childMarkdownRemark.html;
    } catch (err) {
      console.error(err);
      content = '';
    }
    return (
      <div>
        <SEO keywords={keywords} />
        <Header isUserLoggedIn={isUserLoggedIn} userMobile={userMobile} />
        <InsightBody>
          <div className='layout'>
            <div onClick={() => this.articleThumbnailClick(`${sortedData[0].node.articleUrl}`)} className='heroSection'>
              <ArticleImage src={sortedData[0].node.thumbnail.fixed.src} alt='' className='lg' />
              <div className='carouselWrpr'>
                <div className='carouselItem'>
                  <a onClick={() => (window.location = `/insights/${sortedData[0].node.articleUrl}`)}>
                    <MainTitle>{sortedData[0].node.articleHeading}</MainTitle>
                    <TitleBody>
                      <HTMLContent className='content' content={content} />
                    </TitleBody>
                    <p style={{ textAlign: 'right' }}>
                      <ArticleDate>{sortedData[0].node.publishDate}</ArticleDate>
                    </p>
                  </a>
                </div>
              </div>
            </div>
            <section className='articleWrpr'>
              {sortedData.slice(1).map((article, index) => {
                return (
                  <div
                    key={index}
                    onClick={() => this.articleThumbnailClick(`${article.node.articleUrl}`)}
                    className='col'>
                    <a onClick={() => (window.location = `/insights/${article.node.articleUrl}`)}>
                      <ArticleFigure>
                        <ArticleImage src={article.node.thumbnail.fixed.src} alt='' className='sm' />
                        <figcaption>
                          <Title>{article.node.articleHeading}</Title>
                          <Separator />
                          <p>
                            <ArticleDate>{article.node.publishDate}</ArticleDate>
                          </p>
                        </figcaption>
                      </ArticleFigure>
                    </a>
                  </div>
                );
              })}
              <div onClick={() => this.articleThumbnailClick(`${sortedData[0].node.articleUrl}`)} className='col'>
                <a onClick={() => (window.location = `/insights/${sortedData[0].node.articleUrl}`)}>
                  <ArticleFigure>
                    <ArticleImage src={sortedData[0].node.thumbnail.fixed.src} alt='' className='sm' />
                    <figcaption>
                      <Title>{sortedData[0].node.articleHeading}</Title>
                      <Separator />
                      <p>
                        <ArticleDate>{sortedData[0].node.publishDate}</ArticleDate>
                      </p>
                    </figcaption>
                  </ArticleFigure>
                </a>
              </div>
            </section>
          </div>
        </InsightBody>
        <Footer />
      </div>
    );
  }
}

export const query = graphql`
  query {
    allContentfulArticles {
      edges {
        node {
          id
          order
          articleHeading
          articleDescription {
            childMarkdownRemark {
              html
            }
          }
          articleUrl
          publishDate
          thumbnail {
            fixed(width: 1500) {
              src
            }
          }
          articleDetail {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
  }
`;

insights.propTypes = {
  data: PropTypes.object
};
